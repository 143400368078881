import React from 'react';
import { Link } from 'react-router-dom';
import pin from '../assets/pin2.jpg'
const Public_Navbar = ({myListshow}) => {
  console.log("mylistshow in navbar",myListshow)
  return (
    <nav className={myListshow? "hideme":"main-nav"}>
    <div className="container-fluid">
    <div className="row navrow">
        <div className='col-md-8 col-6 '>
 <li className="nav-listitem">
<div className="mobile-details">
<Link to="#" className="nav-link">
<i className="fa-solid fa-phone me-2"></i>
<span className="fw-semibold">+91-8544884846</span>
</Link>
<Link to="#" className="nav-link landline">

<span className="fw-semibold">+91-8288854846</span>
</Link>
                </div>
            </li>
            
            </div>
        <div className='col-md-4 col-6'> 
        <li className="nav-listitem">
            <div className="nav-listicondiv">
                <Link to="https://www.facebook.com/people/TechEngineer/100083472395433/" className='nav-link me-2'>
                <i className="fa-brands fa-facebook-f"/>
                </Link>
                <Link to="https://www.instagram.com/tech___engineer/" className='nav-link me-2'>
                
                <i className="fa-brands fa-instagram"/>
                </Link>
                <Link to="https://www.youtube.com/results?search_query=tech+engineer" className='nav-link me-2'>
                
                <i className="fa-brands fa-youtube social-icon-photo"/>
                </Link>
                <Link to="#" className='nav-link me-2'>
                
                <i className="fa-brands fa-whatsapp social-icon-photo"></i>
                </Link>
                <Link to="https://in.pinterest.com/indertechengineer/" className='nav-link me-2 '>
                
             
                <i className="fa-brands fa-pinterest social-icon-photo"></i>
                </Link>
                {/* <Link to="#" className="nav-link me-2">
                <i className="fa-brands fa-linkedin social-icon-photo"></i>
                </Link>
                <Link  to="#" className="nav-link me-2">
                <i className="fa-brands fa-skype social-icon-photo"></i>
                </Link> */}
               
            </div>
            </li>
    </div>
    </div>
    </div>
    </nav>
  )
}

export default Public_Navbar

import { useState } from 'react';
import './App.css';
import {Routes,Route} from 'react-router-dom';
import Public_layout from './Public-pages/Public_layout';
import DigitalTransform from './Public-pages/DigitalTransform';
import Webdevelopment from './Public-pages/Webdevelopment';
import WebDesigning from './Public-pages/WebDesigning';
import MobileApps from './Public-pages/MobileApps';
import WebPortfolio from './Public-pages/WebPortfolio';
import MobilePortfolio from './Public-pages/MobilePortfolio'
import CaseStudy from './Public-pages/CaseStudy';
import Blogs from './Public-pages/Blogs';
function App() {
  const[myListshow,setMyListshow]=useState('');

function handleListShow(listshow){
setMyListshow(listshow);

  }
  return (
   
    <div className="App">
  {console.log("mylistshow naila in app.js",myListshow)}
<Routes>
<Route path="/" element={<Public_layout getListShow={handleListShow} myListshow={myListshow}/>}>
<Route path="/" element={<DigitalTransform myListshow={myListshow}/>} />
  <Route path="/webdevelopment" element={<Webdevelopment/>}/>
  <Route path="/webdesigning" element={<WebDesigning/>}/>
 <Route path="/mobileapps" element={<MobileApps/>}/>
 <Route path="/portfolio" element={<WebPortfolio/>}/>
 <Route path="/mobileportfolio" element={<MobilePortfolio/>}/>

<Route path="/blog" element={<Blogs/>}/>
<Route path="/casestudy" element={<CaseStudy/>}/>
  </Route>
</Routes>
    </div>
  );
}

export default App;

import React from 'react';
import wall from '../assets/wall.png';
import k6 from '../assets/k6.jpg';
import k1 from '../assets/k1.jpg';
import card1 from '../assets/card1.svg';
import mobile from '../assets/mobile.svg';
import programming from '../assets/programming.jpg';
import card2 from '../assets/card2.svg';
import card3 from '../assets/card3.svg';
import card4 from '../assets/card4.svg';
import card5 from '../assets/card5.svg';
import card6 from '../assets/card6.svg';
import card88 from '../assets/card88.svg';
import Slider from "react-slick";
import slider1 from '../assets/sliderproject5.webp';
import slider2 from '../assets/sliderproject66.webp';
import slider3 from '../assets/sliderproject77.webp';
import logo from '../assets/logoproject2.png';
import logo1 from '../assets/projectlogo3.png';
import blue from '../assets/bluebg.png';
import o from '../assets/overviewicon1.svg';
import sky from '../assets/sky.webp';
import thinktanks from '../assets/thinktanks.svg';
import country from '../assets/countries.svg';
import clients from '../assets/clients.svg';
import retention from '../assets/retention.svg';
import CountUp from 'react-countup';
import flat from '../assets/flat.jpg';
import skorzi from '../assets/skorzi.webp';
import realhealth from '../assets/realhealth.webp'
const WebDesigning = () => {

    var settings = {
        autoplay:true,
        dots: false,
        infinite:true,
        speed:250,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:true,
        


        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                
              }
            },
            
           
           
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]




      };
  return (
    <>
      <div className='webdesignmaindiv'>
      <div className='position-relative'>
        <div className='spiralimgdiv'>
        <img src={wall} alt="image" className='digitalImage'/>
        </div> 
      </div>
<div className='container'>
<div className='row d-flex justify-content-between'>
        <div className='col-md-12 col-lg-5'>
        <div>
            <h1 className='text-start webdesignheader'>Website Design Services</h1>
        </div>
        <div>
            <p className='text-start webdesignpara'> 
            Ideas are like rabbits, by the time you execute one, you have a dozen banging your head. Web application can best be explained as successful and efficient execution of an idea. Tech Prastish has gained expertise in the execution by transforming hundreds of amazing ideas into brilliant web applications. Our experience based strong foundation enables us to transform a basic idea into a full featured database dependent web application. We write the codes with the most advanced technologies which make them clean, reliable and enhanceable. We create secure and bug free applications for your business which are capable of heavy lifting yet easy to maintain. Above all the applications developed in Tech Prastish are so robust that they never disintegrate due to the failure of one or the other component.
            </p>
        </div>
        </div>
        <div className='col-md-12 col-lg-5 dgdiv1main '>
            <div className='dgdiv1'>

            
            <img src={flat} alt="image" className='imagedgslider1' />
            </div>
        </div>
    </div>
</div>
</div>

<div className='ideaswebdesign'>
<div className='container cardcontainer'>
<div className='row'>
                <div className='col-md-12'>
                <h1 className="webdevsection2header">Benefits of Website Design</h1>

                </div>
            </div>

<div className='row'>
<div className='col-md-6 col-lg-4 glimpsecardcol' >
<div className=' glimpsecard'>
    <h1 className='text-start glimpsecardphotodiv'>
      <img src={card1} alt="card-image" className='cardicon'/>  
    </h1>
    <div className="glimpsecardheader">
<h4 className='text-start'>Well commented & documented</h4>
    </div>
    <div>
        <p className='text-start glimpsecardpara'>
        No doubt our code is self documented and we work on deadlines too, but pressure of deadlines never makes us feel uncomfortable to maintain the external documentation where ever it’s required
        </p>
    </div>
</div>    
    
</div> 

<div className='col-md-6 col-lg-4 glimpsecardcol' >
<div className=' glimpsecard'>
    <h1 className='text-start glimpsecardphotodiv'>
      <img src={card1} alt="card-image" className='cardicon'/>  
    </h1>
    <div className="glimpsecardheader">
<h4 className='text-start'>Well commented & documented</h4>
    </div>
    <div>
        <p className='text-start glimpsecardpara'>
        No doubt our code is self documented and we work on deadlines too, but pressure of deadlines never makes us feel uncomfortable to maintain the external documentation where ever it’s required
        </p>
    </div>
</div>    
    
</div>
<div className='col-md-6 col-lg-4 glimpsecardcol' >
<div className=' glimpsecard'>
    <h1 className='text-start glimpsecardphotodiv'>
      <img src={card1} alt="card-image" className='cardicon'/>  
    </h1>
    <div className="glimpsecardheader">
<h4 className='text-start'>Well commented & documented</h4>
    </div>
    <div>
        <p className='text-start glimpsecardpara'>
        No doubt our code is self documented and we work on deadlines too, but pressure of deadlines never makes us feel uncomfortable to maintain the external documentation where ever it’s required
        </p>
    </div>
</div>    
    
</div>

<div className='col-md-6 col-lg-4 glimpsecardcol' >
<div className=' glimpsecard'>
    <h1 className='text-start glimpsecardphotodiv'>
      <img src={card1} alt="card-image" className='cardicon'/>  
    </h1>
    <div className="glimpsecardheader">
<h4 className='text-start'>Well commented & documented</h4>
    </div>
    <div>
        <p className='text-start glimpsecardpara'>
        No doubt our code is self documented and we work on deadlines too, but pressure of deadlines never makes us feel uncomfortable to maintain the external documentation where ever it’s required
        </p>
    </div>
</div>    
    
</div>

<div className='col-md-6 col-lg-4 glimpsecardcol' >
<div className=' glimpsecard'>
    <h1 className='text-start glimpsecardphotodiv'>
      <img src={card1} alt="card-image" className='cardicon'/>  
    </h1>
    <div className="glimpsecardheader">
<h4 className='text-start'>Well commented & documented</h4>
    </div>
    <div>
        <p className='text-start glimpsecardpara'>
        No doubt our code is self documented and we work on deadlines too, but pressure of deadlines never makes us feel uncomfortable to maintain the external documentation where ever it’s required
        </p>
    </div>
</div>    
    
</div>
<div className='col-md-6 col-lg-4 glimpsecardcol' >
<div className=' glimpsecard'>
    <h1 className='text-start glimpsecardphotodiv'>
      <img src={card1} alt="card-image" className='cardicon'/>  
    </h1>
    <div className="glimpsecardheader">
<h4 className='text-start'>Well commented & documented</h4>
    </div>
    <div>
        <p className='text-start glimpsecardpara'>
        No doubt our code is self documented and we work on deadlines too, but pressure of deadlines never makes us feel uncomfortable to maintain the external documentation where ever it’s required
        </p>
    </div>
</div>    
    
</div>

</div>
</div>
</div>

<div className="deliveredprojectdiv">
<div className="row projectname ">
<div className='col-12 my-4 p-4 '>
    <h1 className='deliveredprojects'>Delivered Projects</h1>
</div>
</div>

<div>
<div className='container'>
    

 <div className="slidercontainerdiv">
<Slider {...settings}>
<div className='row'>

<div className='d-flex sliderflexibility'>

<div className=' col-sm-12 col-md-12 col-lg-12 col-xl-6 sliderpara'>
        <h1 className='sliderprojectheader m-2 '>
        <img src={logo} alt="logo-image" className='sliderphoto'/>
        </h1>
        <p className='mt-3'>
        Oceanenterprises.com is the website of Ocean Enterprises, a company that provides a wide range of services for marine and underwater industries. Services offered by the company include underwater construction, repair, and maintenance, commercial diving, oceanographic research and survey, submersible design and fabrication, and underwater photography and videography. The website provides detailed information about the services offered by the company, as well as information about the company's team, equipment, and capabilities. The website also includes contact information, a section for careers, and a form to request a quote or further information.
        </p>
</div>
<div className='col-md-12 col-lg-12  col-xl-5 sliderpicturediv'>
<img src={realhealth} alt="image" width="650" className='sliderphoto'/>
</div>

</div>

</div>
<div className='row'>
<div className='d-flex sliderflexibility'>

<div className='col-sm-12 col-md-12 col-lg-12 col-xl-6 '>
<h1 className='sliderprojectheader m-2'>
        <img src={logo1} alt="logo-image" className='sliderphoto'/>
        </h1>
    <p className='sliderpara'>
       Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
       </p>
    </div>
<div className= ' col-sm-12 col-md-12 col-lg-12 col-xl-5 sliderpicturediv'>
    <div>
    <img src={skorzi} alt="image" width="650" className='sliderphoto'/>

    </div>
</div>

</div>
  

   </div>
   
</Slider>
</div>
    </div>

</div>

<div className="quickfactsbackground">
    <div className='container p-4'>
        <div className='row quickfactsrow'>
            <div className='col-md-12'>
                <div className='text-center'>
           <h1 className='text-white text-center quickfactsheader'>Quick Facts</h1>         
                <p className='text-white quickfactsparagraph'>What you need to know about us</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div className='overviewcontainer'>

<div className='container'>
<div className='row'>
<div className='col-md-12'>
<h4 className='text-start text-white quickcardtitle'>Overview</h4>
</div>
<div className='d-flex justify-content-around quickcardcontainer'>


<div className='col-sm-12 col-md-6 col-lg-3  quickcard'>
<div className='d-flex'>
<div>
<img src={thinktanks} alt="image"/>
    </div>
    <div className=' ms-4 d-flex flex-column '>
     <h5 className='text-start quickcardtext'><span>135+</span>
    <br/>
    Think Tanks    
    </h5>   
    </div>
</div>
</div>


<div className='col-sm-12 col-md-6 col-lg-3   quickcard'>
<div className='d-flex'>
<div>
<img src={country} alt="image"/>
    </div>
    <div className=' ms-4 d-flex flex-column '>
     <h5 className='text-start quickcardtext'><span>135+</span>
    <br/>
    Countries
    </h5>   
    </div>
</div>
</div>



<div className='col-sm-12 col-md-6 col-lg-3   quickcard'>
<div className='d-flex'>
<div>
<img src={clients} alt="image"/>
    </div>
    <div className=' ms-4 d-flex flex-column '>
     <h5 className='text-start quickcardtext'><span>135+</span>
    <br/>
    Clients   
    </h5>   
    </div>
</div>
</div>


<div className='col-md-6 col-sm-12  col-lg-3  quickcard'>
<div className='d-flex'>
<div>
<img src={retention} alt="image"/>
    </div>
    <div className=' ms-4 d-flex flex-column '>
     <h5 className='text-start quickcardtext'><span>135+</span>
    <br/>
    Client Retention    
    </h5>   
    </div>
</div>
</div>
</div>
</div>
</div>
</div>
<div className=" achievementsdiv">
    <div className='container py-4'>
        <div className='row achievementsrow'>
            <div className='col-md-12'>
                <h4 className='text-start achievementstitle'>Achivements</h4>
            </div>
        </div>
        <div className='row d-flex justify-content-around'>
      <div className='col-md-6 col-sm-12 col-lg-3 quickcard'>
<div className='d-flex'>
    <div>
        <img src={mobile} alt="image"/>
    </div>
    <div className=' ms-4 d-flex flex-column '>
     <h5 className='text-start quickcardtext'><span>70+</span>
    <br/>
    Apps Published  
    </h5>   
    </div>
</div>
      </div>
      <div className='col-md-6 col-sm-12 col-lg-3 quickcard'>
<div className='d-flex'>
    <div>
        <img src={mobile} alt="image"/>
    </div>
    <div className=' ms-4 d-flex flex-column '>
     <h5 className='text-start quickcardtext'><span>35+</span>
    <br/>
    Cross platform Apps  
    </h5>   
    </div>
</div>
      </div>

      <div className='col-md-6 col-sm-12 col-lg-3 quickcard'>
<div className='d-flex'>
    <div>
        <img src={mobile} alt="image"/>
    </div>
    <div className=' ms-4 d-flex flex-column '>
     <h5 className='text-start quickcardtext'><span><CountUp end={2500}/>+</span>
    <br/>
    Websites Published  
    </h5>   
    </div>
</div>
      </div>


      <div className='col-md-6 col-sm-12 col-lg-3 quickcard'>
<div className='d-flex'>
    <div>
        <img src={mobile} alt="image"/>
    </div>
    <div className=' ms-4 d-flex flex-column '>
     <h5 className='text-start quickcardtext'><span>1.5M+</span>
    <br/>
    Development Hours   
    </h5>   
    </div>
</div>
      </div>
        </div>


        <div className='row achievementsrow '>
            <div className='col-md-12'>
                <h4 className='text-start achievementstitle'>Technology Facts</h4>
            </div>
        </div>


        <div className='row d-flex justify-content-around technologyfactsrow'>
      <div className='col-md-6 col-sm-12 col-lg-3 quickcard'>
<div className='d-flex'>
    <div>
        <img src={mobile} alt="image"/>
    </div>
    <div className=' ms-4 d-flex flex-column '>
     <h5 className='text-start quickcardtext'><span>135+</span>
    <br/>
    Client Retention    
    </h5>   
    </div>
</div>
      </div>
      <div className='col-md-6 col-sm-12 col-lg-3 quickcard'>
<div className='d-flex'>
    <div>
        <img src={mobile} alt="image"/>
    </div>
    <div className=' ms-4 d-flex flex-column '>
     <h5 className='text-start quickcardtext'><span>135+</span>
    <br/>
    Client Retention    
    </h5>   
    </div>
</div>
      </div>

      <div className='col-md-6 col-sm-12 col-lg-3 quickcard'>
<div className='d-flex'>
    <div>
        <img src={mobile} alt="image"/>
    </div>
    <div className=' ms-4 d-flex flex-column '>
     <h5 className='text-start quickcardtext'><span>135+</span>
    <br/>
    Client Retention    
    </h5>   
    </div>
</div>
      </div>


      <div className='col-md-6 col-sm-12 col-lg-3 quickcard'>
<div className='d-flex'>
    <div>
        <img src={mobile} alt="image"/>
    </div>
    <div className=' ms-4 d-flex flex-column '>
     <h5 className='text-start quickcardtext'><span><CountUp end={135}/>+</span>
    <br/>
    Client Retention    
    </h5>   
    </div>
</div>
      </div>
        </div>
    </div>
</div>





</div>


    </>
  )
}

export default WebDesigning

import React from 'react'
import { Link } from 'react-router-dom';
import findus from '../assets/findUs.svg'
import services from '../assets/services.svg'
import contactus from '../assets/contactus.svg'
const Public_footer = () => {
  return (
    <div className='mb-5'>
      <div className="footerbook">
<div className="row footerbookrow">
  <div className="col-12">
<h4 className="text-start">
  <img src={findus} alt="image" className="findusphoto"/>
  Find Us
</h4>

</div>

</div>
<div className="row mt-3">
  <div className="col-sm-12 col-md-4">
    <h4 className="text-start">India</h4>
    <p className="text-start addresstext">E-51 Ground-floor Industrial area phase-8b Mohali,Punjab(India)</p>
  </div>
  <div className="col-sm-12 col-md-4">
  <h4 className="text-start">Canada</h4>
    <p className="text-start addresstext">121 Marina Key chastermere Alberta T1X 1Y7(Canada)</p>
  </div>
  <div className="col-sm-12 col-md-4">
    
  </div>
</div>
{/* subhan */}
<div className="row mt-4">
  <div className="col-md-7">
    <h4 className="text-start">
      <img src={services} alt="image" className="findusphoto"/>
      <span className="ms-2">Services</span>
    </h4>

<div className="d-flex servicesontainer">
  <ul className="ps-0">
    <li className=" text-start list-unstyled listbox">
      <Link to="/webdevelopment" className="text-decoration-none text-white position-relative">
        <span className="customstyle">
        Web Development
        </span>
       
        </Link>
    </li>



    <li className=" text-start list-unstyled listbox">
      <Link to="#" className="text-decoration-none text-white position-relative">
        <span className="customstyle">
        Web Designing
        </span>
       
        </Link>
    </li>


    <li className=" text-start list-unstyled listbox">
      <Link to="#" className="text-decoration-none text-white position-relative">
        <span className="customstyle">
        Digital Marketing
        </span>
       
        </Link>
    </li>


    <li className=" text-start list-unstyled listbox">
      <Link to="#" className="text-decoration-none text-white position-relative">
        <span className="customstyle">
        Mobile Application Development
        </span>
       
        </Link>
    </li>

    <li className=" text-start list-unstyled listbox">
      <Link to="#" className="text-decoration-none text-white position-relative">
        <span className="customstyle">
        UI/UX Designing
        </span>
       
        </Link>
    </li>
  </ul>
  <ul className="ps-0" >
    <li className=" text-start list-unstyled listbox">
      <Link to="#" className="text-decoration-none text-white position-relative">
        <span className="customstyle">
        Web Development
        </span>
       
        </Link>
    </li>



    <li className=" text-start list-unstyled listbox">
      <Link to="#" className="text-decoration-none text-white position-relative">
        <span className="customstyle">
        Web Designing
        </span>
       
        </Link>
    </li>


    <li className=" text-start list-unstyled listbox">
      <Link to="#" className="text-decoration-none text-white position-relative">
        <span className="customstyle">
        Digital Marketing
        </span>
       
        </Link>
    </li>


    <li className=" text-start list-unstyled listbox">
      <Link to="#" className="text-decoration-none text-white position-relative">
        <span className="customstyle">
        Mobile Application Development
        </span>
       
        </Link>
    </li>

    <li className=" text-start list-unstyled listbox">
      <Link to="#" className="text-decoration-none text-white position-relative">
        <span className="customstyle">
        UI/UX Designing
        </span>
       
        </Link>
    </li>
  </ul>

</div>



  </div>
  <div className="col-md-4 contactdiv">
    <h4 className="text-start">
    <img src={contactus} alt="contact-image" className="findusphoto"/>
    <span>Contact Us</span>
    </h4>
    <div className="callusdiv">
      <h5 className="text-start">Call Us</h5>
      <div className="text-start">
        <span>+91-9814453900</span>
        <span>,</span>
        <span>01675-253900</span>
      </div>
    </div>

    <div className=" mt-3">
      <h5 className="text-start">Email for Business</h5>
      <div className="text-start">
        <span>info@techengineer.com</span>
       
      </div>
    </div>

    <div className=" mt-3">
      <h5 className="text-start">Email for job</h5>
      <div className="text-start">
        <span>hr@techengineer.com</span>
       
      </div>
    </div>

  </div>
  

</div>
</div>
    </div>
  )
}

export default Public_footer;

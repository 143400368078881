import React,{useEffect,useState} from 'react';
import { Link } from 'react-router-dom';
import imageblog from '../assets/imageblog.webp'
import postphoto1 from '../assets/postphoto1.webp'
const Blogs = () => {
  const [scroll,setscroll]=useState(false)
  useEffect(()=>{
    function handleScroll(){
     if(window.scrollY<=1544)
     {
      console.log("scrollY value inside function",window.scrollY)
setscroll(true)
     }
     else
     {
      setscroll(false)
     }
    }

window.addEventListener('scroll',handleScroll)
 
return ()=>{
window.removeEventListener('scroll',handleScroll)
 } 


},[scroll])

  return (
    <>
   
    
       <div className='portfoliobackImagediv'>
    <div className='container'>
        <div className='row '>
        <div className="col-md-12">
         <h2 className="portfolioheader portfolioheadertext">Blog</h2>
        
            <h5 className='mt-3'>
                <Link to="/" className='text-decoration-none'>
                <span className="portfoliohometext portfolioheader  portfoliotextsection ml-2">HOME</span>

                </Link>
                
                
                <span className="ms-1 portfolioheader  portfoliotextsection">Blog</span>
                </h5>
            
            
            
        </div>    
        </div>
    </div>
    <div className='downdiv'>
        <a href="#focussectionblog">
        <i className="fa-solid fa-arrow-down arrowcolor"></i>
        </a>
    </div>
    </div>
    <div className='container blogcontainer'>
     <div className='row'>
        <div className='col-md-12 col-lg-8'>
<div className='p-2'>

        
        <div>
          <img src={imageblog} alt="image"  className='imageblog'/>  
        </div>
           <div className='text-start usernameMaindiv'>
            <span className='usernameMain'>
             <span className="username">
             <i className="fas fa-user-circle"></i>
                  
             </span> 
             Naila Iftikhar
            </span>
            <span className='ms-4 usernameMain'>
            <span className="username">
             <i className="fa-solid fa-clock"></i>
                  
             </span> 
             November24,2023
            </span>
            </div> 




<div className=" mt-3">
  <h3 className='text-start postbox-title' >
    <Link to="#" className='text-decoration-none  ' >
    The Manifest Hails Tech Prastish Software Solutions Pvt. Ltd as one of the Most Reviewed App Developers in Vancouver 
      </Link>
  </h3>
  <div className='text-start'>
    <p className='postbox-textpara'> Tech Engineers is very proud to announce that we’ve been recognized as a 2022 The Manifest Leader. The Manifest has named us as one of the Most Reviewed App Development Companies in Vancouver. This is a huge accomplishment for our team, and we are delighted to celebrate this amazing award with </p>
  </div>
  <div className=' d-flex justify-content-start'>
    <Link to="#" className="readmorelink text-decoration-none">READ MORE</Link>
  </div>
</div>
</div>



<div className=' p-2  mt-5 '>

        
<div>
  <img src={imageblog} alt="image" width="100%" className='imageblog'/>  
</div>
   <div className=' usernameMaindiv text-start'>
    <span className='usernameMain'>
     <span className="username">
     <i className="fas fa-user-circle"></i>
          
     </span> 
     Naila Iftikhar
    </span>
    <span className='ms-4 usernameMain'>
    <span className="username">
     <i className="fa-solid fa-clock"></i>
          
     </span> 
     November24,2023
    </span>
    </div> 




<div className=" mt-3">
<h3 className='text-start postbox-title' >
<Link to="#" className='text-decoration-none  ' >
The Manifest Hails Tech Prastish Software Solutions Pvt. Ltd as one of the Most Reviewed App Developers in Vancouver 
</Link>
</h3>
<div className='text-start'>
<p className='postbox-textpara'> Tech Engineers is very proud to announce that we’ve been recognized as a 2022 The Manifest Leader. The Manifest has named us as one of the Most Reviewed App Development Companies in Vancouver. This is a huge accomplishment for our team, and we are delighted to celebrate this amazing award with </p>
</div>
<div className=' d-flex justify-content-start'>
<Link to="#" className="readmorelink text-decoration-none">READ MORE</Link>
</div>
</div>
</div>
<div className=' p-2 mt-5 '>

        
<div>
  <img src={imageblog} alt="image" width="100%" className='imageblog'/>  
</div>
   <div className=' usernameMaindiv text-start'>
    <span className='usernameMain'>
     <span className="username">
     <i className="fas fa-user-circle"></i>
          
     </span> 
     Naila Iftikhar
    </span>
    <span className='ms-4 usernameMain'>
    <span className="username">
     <i className="fa-solid fa-clock"></i>
          
     </span> 
     November24,2023
    </span>
    </div> 




<div className=" mt-3 ">
<h3 className='text-start postbox-title' >
<Link to="#" className='text-decoration-none  ' >
The Manifest Hails Tech Prastish Software Solutions Pvt. Ltd as one of the Most Reviewed App Developers in Vancouver 
</Link>
</h3>
<div className='text-start'>
<p className='postbox-textpara'> Tech Engineers is very proud to announce that we’ve been recognized as a 2022 The Manifest Leader. The Manifest has named us as one of the Most Reviewed App Development Companies in Vancouver. This is a huge accomplishment for our team, and we are delighted to celebrate this amazing award with </p>
</div>
<div className=' d-flex justify-content-start'>
<Link to="#" className="readmorelink text-decoration-none">READ MORE</Link>
</div>
</div>
</div>
 </div>

<div className='col-md-12 col-lg-3 ms-3'>
<div className={scroll? 'sticky-div' : 'sticky-div1'}>
<div className='mb-4 searchmainbox'>
  <h4 className='text-start searchtext'>Search here</h4>
  <div className='mt-3 searchinputparent'>
    <input type="text" placeholder='search Your Keywords...' name="search" className='searchinput'/>
  <div className='searchinputicon'>
  <i className="fa-solid fa-magnifying-glass"></i>
  </div>
  
  </div>
</div>
<div className='mt-5 recentpostdiv'>
  <h4 className='text-start '>Recent Posts</h4>
 <div className='border-border-warning d-flex'>
<div className='me-3 blogphotomargin'>
<img src={postphoto1} alt="photo" width="122px" className='blogsmallphoto'/>
</div>
<div className='text-start ms-2'>
  <span className='postdate'>2022-11-24</span>
  <span className='ms-2 postdate'>11:18:24</span>
 <p className=' text-start postcontenttext'>The Manifest Hails Tech Prastish Software Solutions Pvt. Ltd as one of the Most Reviewed App Developers in Vancouver</p>
</div>
 </div>
 
 <div className=' d-flex'>
<div className='me-3 blogphotomargin'>
<img src={postphoto1} alt="photo" width="122px"/>
</div>
<div className='text-start ms-2'>
  <span className='postdate'>2022-11-24</span>
  <span className='ms-2 postdate'>11:18:24</span>
 <p className='  text-start postcontenttext'>The Manifest Hails Tech Prastish Software Solutions Pvt. Ltd as one of the Most Reviewed App Developers in Vancouver</p>
</div>
 </div>


 <div className=' d-flex'>
<div className='me-3 blogphotomargin'>
<img src={postphoto1} alt="photo" width="122px"/>
</div>
<div className='text-start ms-2'>
  <span className='postdate'>2022-11-24</span>
  <span className='ms-2 postdate'>11:18:24</span>
 <p className='  text-start postcontenttext'>The Manifest Hails Tech Prastish Software Solutions Pvt. Ltd as one of the Most Reviewed App Developers in Vancouver</p>
</div>
 </div>

 <div className='d-flex'>
<div className='me-3 blogphotomargin'>
<img src={postphoto1} alt="photo" width="122px"/>
</div>
<div className='text-start ms-2'>
  <span className='postdate'>2022-11-24</span>
  <span className='ms-2 postdate'>11:18:24</span>
 <p className=' text-start postcontenttext'>The Manifest Hails Tech Prastish Software Solutions Pvt. Ltd as one of the Most Reviewed App Developers in Vancouver</p>
</div>
 </div>
 <div className=' d-flex'>
<div className='me-3 blogphotomargin'>
<img src={postphoto1} alt="photo" width="122px"/>
</div>
<div className='text-start ms-2'>
  <span className='postdate'>2022-11-24</span>
  <span className='ms-2 postdate'>11:18:24</span>
 <p className=' postcontenttext'>The Manifest Hails Tech Prastish Software Solutions Pvt. Ltd as one of the Most Reviewed App Developers in Vancouver</p>
</div>
 </div>
 <div className=' d-flex'>
<div className='me-3 blogphotomargin'>
<img src={postphoto1} alt="photo" width="122px"/>
</div>
<div className='text-start ms-2'>
  <span className='postdate'>2022-11-24</span>
  <span className='ms-2 postdate'>11:18:24</span>
 <p className='  postcontenttext'>The Manifest Hails Tech Prastish Software Solutions Pvt. Ltd as one of the Most Reviewed App Developers in Vancouver</p>
</div>
 </div>
 <div className=' d-flex'>
<div className='me-3 blogphotomargin'>
<img src={postphoto1} alt="photo" width="122px"/>
</div>
<div className='text-start ms-2'>
  <span className='postdate'>2022-11-24</span>
  <span className='ms-2 postdate'>11:18:24</span>
 <p className=' postcontenttext'>The Manifest Hails Tech Prastish Software Solutions Pvt. Ltd as one of the Most Reviewed App Developers in Vancouver</p>
</div>
 </div>
 <div className=' d-flex'>
<div className='me-3 blogphotomargin'>
<img src={postphoto1} alt="photo" width="122px"/>
</div>
<div className='text-start ms-2'>
  <span className='postdate'>2022-11-24</span>
  <span className='ms-2 postdate'>11:18:24</span>
 <p className='postcontenttext'>The Manifest Hails Tech Prastish Software Solutions Pvt. Ltd as one of the Most Reviewed App Developers in Vancouver</p>
</div>
 </div>
 <div className=' d-flex'>
<div className='me-3 blogphotomargin'>
<img src={postphoto1} alt="photo" width="122px"/>
</div>
<div className='text-start ms-2'>
  <span className='postdate'>2022-11-24</span>
  <span className='ms-2 postdate'>11:18:24</span>
 <p className='postcontenttext'>The Manifest Hails Tech Prastish Software Solutions Pvt. Ltd as one of the Most Reviewed App Developers in Vancouver</p>
</div>
 </div>
  </div>
  </div>
        </div>
    </div>
    </div>
    </>
  )
}

export default Blogs


import React,{useEffect, useState} from 'react';
import I from '../assets/logo2.png'
import { Link } from 'react-router-dom';

const Public_Header = ({getListShow}) => {
const [listShow,setListShow]=useState(false);
const [isSticky,setSticky]=useState(false);
getListShow(listShow);

  function handleScroll(){
  
  if(window.scrollY>=100)
  {
    setSticky(true)
  }
  else{
    setSticky(false)
  }
}
window.addEventListener('scroll',handleScroll);
return (

    <nav className={isSticky? "container-fluid header-parent":"container-fluid header-parent2"}>
      <div className="row">

        <div className="col-md-8  col-sm-5 headtexter">
            <h1 className="header-logo-div ">
                <Link to="#" className="logo-text">
             <img src={I} alt="tech engineer" className="techengineerimg" />   
            <span className="tech-text">Tech Engineer</span>
            </Link>
            </h1>
        </div>
        <div className="col-md-3  col-sm-6 headtoggle">
         <span className='bar-icon'>
         <i className={listShow? "fa-solid fa-xmark":"fa-solid fa-bars"} onClick={()=>setListShow(!listShow)}></i>    
        </span>   
        </div>
       
<div className={listShow?'menu-list-div':'menu-list-div-hide'}>
<ul className='menu-list-ul'>
  <li className='menu-list-li'>
  <Link to="#">Services</Link>
  <ul className='sub-menu-ul'>
    <li className='sub-menu-li' onClick={()=>setListShow(!listShow)}>
    <Link to="/webdevelopment" className='textdecoration-none'>
      <span className='sub-menu-text'>Web Development</span>
    </Link>
</li>
    <li className='sub-menu-li' onClick={()=>setListShow(!listShow)}>
    <Link to="/webdesigning" className='textdecoration-none'>
      <span>Web Designing</span>
    </Link>
    </li>
    <li className='sub-menu-li' onClick={()=>setListShow(!listShow)} >
    <Link to="/mobileapps" className='textdecoration-none'>
      <span>Mobile Application Development</span>
    </Link>
    </li>
  </ul>
  </li>
  <li className='menu-list-li-portfolio'>
    <Link to="#">Portfolio</Link>
<ul className='sub-menu-ul'>
  <li className='sub-menu-li' onClick={()=>setListShow(!listShow)}>
    <Link to="/portfolio">Web</Link>
  </li>
  <li className='sub-menu-li' onClick={()=>setListShow(!listShow)}  >
    <Link to="/mobileportfolio">Mobile</Link>
  </li>
  <li className='sub-menu-li' onClick={()=>setListShow(!listShow)}  >
    <Link to="/casestudy">Case Study</Link>
  </li>
</ul>
  </li>
  
  <li className='menu-list-li-blog' onClick={()=>setListShow(!listShow)}>
    <Link to="/blog">Blog</Link>
  </li>
  <li className='menu-list-li-about'>
    <Link to="#">About</Link>
  </li>
</ul>

<div className='follow-us-div'>
  {/* <div>
    <div></div>
  </div> */}
  <div className="follow-us-title">
    <h5>Follow us</h5>
  </div>
  <ul className='follow-us-ul'>
    {/* <li className='follow-us-li followus'>Follow Us:</li> */}
    <li className='follow-us-li'>
      <Link to="https://www.facebook.com/people/TechEngineer/100083472395433/">Facebook</Link>
    </li>
    <li className='follow-us-li'>
      <Link to="#">Whatsapp</Link>
    </li>
    <li className='follow-us-li'>
      <Link to="https://www.youtube.com/results?search_query=tech+engineer">Youtube</Link>
    </li>
    <li className='follow-us-li'>
      <Link to="https://www.instagram.com/tech___engineer/">Instagram</Link>
    </li>
    <li className='follow-us-li'>
      <Link to="https://in.pinterest.com/indertechengineer/">Pinterest</Link>
    </li>
  </ul>
</div>
</div>
      </div>
    </nav>
    
  )
}

export default Public_Header

import React from 'react';
import { Link } from 'react-router-dom';
import cottage from '../assets/cottage.jpg'
import alex from '../assets/alex.webp'
const CaseStudy = () => {
  return (
    <>
    <div className='portfoliobackImagediv'>
    <div className='container'>
        <div className='row '>
        <div className="col-md-12">
         <h2 className="portfolioheader portfolioheadertext">Case Study</h2>
        
            <h5 className='mt-3'>
                <Link to="/" className='text-decoration-none'>
                <span className="portfoliohometext portfolioheader  portfoliotextsection ml-2">HOME</span>

                </Link>
                
                
                <span className="ms-1 portfolioheader  portfoliotextsection">Case Study</span>
                </h5>
            
            
            
        </div>    
        </div>
    </div>
    <div className='downdiv'>
        <a href="#focussectioncase">
        <i className="fa-solid fa-arrow-down arrowcolor"></i>
        </a>
    </div>
    </div>
    <div className='casestudy-container ' id="focussectioncase">
        <div className='container'>
            <div className='  row mb-4'>
            <div className='col-md-4'>
             <div className='cardstudy'> 
             <div className='casestudyphotodiv'>
         <img src={alex} alt="image" width="100%"/>
             </div>
             <div>
                <h2 className='text-start casestudyheader'>Arenus</h2>
             </div>
             <div>
                <p className='text-start casestudypara'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
               
                </p>
             </div>
             </div>   
            </div>
            <div className='col-md-4'>
            <div className='cardstudy'> 
             <div className='casestudyphotodiv'>
         <img src={alex} alt="image" width="100%"/>
             </div>
             <div>
                <h2 className='text-start casestudyheader'>Arenus</h2>
             </div>
             <div>
                <p className='text-start casestudypara'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
               
                </p>
             </div>
             </div>
                </div>
            <div className='col-md-4 '>
            <div className='cardstudy'> 
             <div className='casestudyphotodiv'>
         <img src={alex} alt="image" width="100%"/>
             </div>
             <div>
                <h2 className='text-start casestudyheader'>Arenus</h2>
             </div>
             <div>
                <p className='text-start casestudypara'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
               
                </p>
             </div>
             </div>
            </div>
            </div>



            <div className='row mb-4'>
            <div className='col-md-4'>
             <div className='cardstudy'> 
             <div className='casestudyphotodiv'>
         <img src={alex} alt="image" width="100%"/>
             </div>
             <div>
                <h2 className='text-start casestudyheader'>Arenus</h2>
             </div>
             <div>
                <p className='text-start casestudypara'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
               
                </p>
             </div>
             </div>   
            </div>
            <div className='col-md-4'>
            <div className='cardstudy'> 
             <div className='casestudyphotodiv'>
         <img src={alex} alt="image" width="100%"/>
             </div>
             <div>
                <h2 className='text-start casestudyheader'>Arenus</h2>
             </div>
             <div>
                <p className='text-start casestudypara'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
               
                </p>
             </div>
             </div>
                </div>
            <div className='col-md-4 '>
            <div className='cardstudy'> 
             <div className='casestudyphotodiv'>
         <img src={alex} alt="image" width="100%"/>
             </div>
             <div>
                <h2 className='text-start casestudyheader'>Arenus</h2>
             </div>
             <div>
                <p className='text-start casestudypara'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
               
                </p>
             </div>
             </div>
            </div>
            </div>
            <div className='row mb-4'>
            <div className='col-md-4 col-sm-12 col-lg-4 '>
             <div className='cardstudy'> 
             <div className='casestudyphotodiv'>
         <img src={alex} alt="image" width="100%"/>
             </div>
             <div>
                <h2 className='text-start casestudyheader'>Arenus</h2>
             </div>
             <div>
                <p className='text-start casestudypara'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
               
                </p>
             </div>
             </div>   
            </div>
            <div className='col-md-4 col-sm-12 col-lg-4'>
            <div className='cardstudy'> 
             <div className='casestudyphotodiv'>
         <img src={alex} alt="image" width="100%"/>
             </div>
             <div>
                <h2 className='text-start casestudyheader'>Arenus</h2>
             </div>
             <div>
                <p className='text-start casestudypara'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
               
                </p>
             </div>
             </div>
                </div>
            <div className='col-md-4  col-sm-12 col-lg-4'>
            <div className='cardstudy'> 
             <div className='casestudyphotodiv'>
         <img src={alex} alt="image" width="100%"/>
             </div>
             <div>
                <h2 className='text-start casestudyheader'>Arenus</h2>
             </div>
             <div>
                <p className='text-start casestudypara'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
               
                </p>
             </div>
             </div>
            </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default CaseStudy

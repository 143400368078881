import React from 'react'
import Public_Navbar from './Public_Navbar';
import { Outlet } from 'react-router-dom';
import Public_Header from './Public_Header';
import Public_footer from './Public_footer';
import Contactus from './Contactus';

const Public_layout = ({myListshow,getListShow}) => {
  return (
    <div>

      <div>
      <Public_Navbar myListshow={myListshow}/>
      <Public_Header getListShow={getListShow}/>
      </div>

      <div>

<Outlet/>
<Contactus/>
<Public_footer/>
      </div>
    </div>
  )
}

export default Public_layout

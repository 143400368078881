 
const dataFile = [
{
   "title":"image1",
   "image": require("../assets/aitutor.jpg")
 },
 {
    "title":"image2",
    "image": require("../assets/fair.jpg")
  },
  {
    "title":"image3",
    "image": require("../assets/astonlee.jpg")
  }
,{
    "title":"image4",
    "image": require("../assets/bay.jpg")
  },
  {
    "title":"image5",
    "image": require("../assets/carlton.jpg")
  }
,{
    "title":"image6",
    "image": require("../assets/topmedia.jpg")
  }
,{
    "title":"image7",
    "image": require("../assets/gifti.jpg")
  }
,{
    "title":"image8",
    "image": require("../assets/harrang.jpg")
  }
,{
    "title":"image9",
    "image": require("../assets/ignite.jpg")
  }
,{
    "title":"image10",
    "image": require("../assets/innova.jpg")
  },
  {
    "title":"image11",
    "image": require("../assets/ishga.jpg")
  }
,{
    "title":"image12",
    "image": require("../assets/jewel.jpg")
  }
,{
    "title":"image13",
    "image": require("../assets/journey.webp")
  }
,{
    "title":"image14",
    "image": require("../assets/license.jpg")
  }
,{
    "title":"image15",
    "image": require("../assets/livee.jpg")
  }
,{
    "title":"image16",
    "image": require("../assets/medical.jpg")
  }
,{
    "title":"image17",
    "image": require("../assets/monster.jpg")
  }
,{
    "title":"image18",
    "image": require("../assets/aitutor.jpg")
  }
,{
    "title":"image19",
    "image": require("../assets/vitamin.jpg")
  }
,{
    "title":"image20",
    "image": require("../assets/tea.jpg")
  }
,{
    "title":"image21",
    "image": require("../assets/steel.jpg")
  }
,{
    "title":"image22",
    "image": require("../assets/navy.jpg")
  }
,{
    "title":"image23",
    "image": require("../assets/steel.jpg")
  },
  {
    "title":"image24",
    "image": require("../assets/vitamin.jpg")
  }

  ,{
    "title":"image25",
    "image": require("../assets/monster.jpg")
  }

  ,{
    "title":"image26",
    "image": require("../assets/livee.jpg")
  }
,{
    "title":"image27",
    "image": require("../assets/tea.jpg")
  }
,{
    "title":"image28",
    "image": require("../assets/steel.jpg")
  }
,{
    "title":"image29",
    "image": require("../assets/navy.jpg")
  }
,{
    "title":"image30",
    "image": require("../assets/steel.jpg")
  }
,{
    "title":"image31",
    "image": require("../assets/livee.jpg")
  }
,{
    "title":"image32",
    "image": require("../assets/tea.jpg")
  }
,{
    "title":"image33",
    "image": require("../assets/steel.jpg")
  }
,{
    "title":"image34",
    "image": require("../assets/medical.jpg")
  }
,{
    "title":"image35",
    "image": require("../assets/steel.jpg")
  }
,{
    "title":"image36",
    "image": require("../assets/livee.jpg")
  }
,
]

export default dataFile;
import React from 'react'

const Contactus = () => {
  return (
    <div className='mt-5'>
      <div className="container formdivis">
<div className="  formmaindiv mb-4 mt-3">
  <div className="formtextcontainer ">
    <h2 className="talkheading">Lets's Talk</h2>
    <p className="talkpara">About Your ideas and concepts</p>

  </div>
  <div className="formcontainerdiv">
<form>
  <div className="d-flex formboxes">



  <div className="formboxesinner">
<input type="text" name="name" placeholder="Name" autoComplete="off" />
<input type="email" name="email" placeholder="Email" autoComplete="off"/>
  
</div>
<div className="formbtndiv">
  <input type="button" value="Submit" className="formbtn" />
</div>
  </div>

  
</form>
  </div>

</div>
</div>

    </div>
  )
}

export default Contactus

import React,{useState,useEffect} from 'react';
import portfolioBack from '../assets/portfoliobackground.webp'
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import '../style/portfolio.css';
import image11 from '../assets/navy.jpg'
import datajsonmobile from '../Data/MobilePortData.json';
import mobile from '../assets/mobilepic.webp';
import phonecover from '../assets/phonecover.webp';
import dataFile from '../Data/dataFile';

const MobilePortfolio = () => {
    const[page,setPage]=useState(1);
const[pageCount,setPagecount]=useState(0);
const[pageSlicedarray,setpageSlicedarray]=useState([])
const[mainarray,setmainarray]=useState(dataFile)
useEffect(()=>{

  // calculate page count
  const pagedatacount=Math.ceil(dataFile.length/6);
  console.log("pagedatacount is=",pagedatacount)
  setPagecount(pagedatacount);
  const limit=6;
  const end=limit*page;
  const start=end-limit;
  const slicedArray=mainarray.slice(start,end);
  console.log("sliced array is====",slicedArray)
  setpageSlicedarray(slicedArray)

},[page])
const handlePrevious=()=>{
    console.log("handle previous called")
    if(page==1)return page;
   setPage(page-1);

  }
  const handleNext=()=>{
    console.log("handle next called")
    if(page==pageCount)
    {
      return page;
    }
    setPage(page+1)
  }
  var settings = {
   dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:false,
   responsive:[
  {
    breakpoint:320,
   settings:{ 
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:true
  }
    }
  
  ]
  };
  return (
    <>
     <div className='portfoliobackImagediv'>
    <div className='container'>
        <div className='row'>
        <div className="col-md-12">
         <h2 className="portfolioheader portfolioheadertext">Delivered With Love</h2>
        
            <h5 className='mt-3'>
                <Link to="/" className='text-decoration-none'>
                <span className="portfoliohometext portfolioheader  portfoliotextsection ml-2">HOME</span>

                </Link>
                
                
                <span className="ms-1 portfolioheader  portfoliotextsection">PORTFOLIO</span>
                </h5>
        </div>    
        </div>
    </div>
    <div className='downdiv'>
        <a href="#focussectionmobile">
        <i className="fa-solid fa-arrow-down arrowcolor"></i>
        </a>
    </div>
    </div>
    <div className='portfolioprojectdiv mt-5 ' id="focussectionmobile">
     <div className='container'>
     <div className='row'>
      {
  datajsonmobile.map((elem,index)=>  
  <div className='col-lg-5 col-md-11 mb-4 mobilemain' key={index} >
          {/* <div className='p-3 portfolioimagecoldiv'>
         
           <img src={image11} alt="image" width="99%" className='portfolioimagecol'/>
           </div>    */}
<h2 className="projectname">{elem.project}</h2>

  <Slider {...settings}>
 <div className='d-flex'>
    <div className='outercoverdiv col-lg-4'>
    <img src={phonecover} alt="coverimage" className='coverimage' />
    
    <img src={mobile} alt="image" width="100%" className='innercoverphoto'/>
  

    </div>
    <div className='outercoverdiv col-lg-4'>
    <img src={phonecover} alt="coverimage" className='coverimage' />
    
    <img src={mobile} alt="image" width="100%" className='innercoverphoto'/>
  

    </div>
    <div className='outercoverdiv col-lg-4'>
    <img src={phonecover} alt="coverimage" className='coverimage' />
    
    <img src={mobile} alt="image" width="100%" className='innercoverphoto'/>
  

    </div>
     {/* <div className='outercoverdiv col-lg-4'>
    <img src={phonecover} alt="coverimage" className='coverimage' />
    
    <img src={mobile} alt="image" width="100%" className='innercoverphoto'/>
  

    </div>
    <div className='outercoverdiv col-lg-4'>
    <img src={phonecover} alt="coverimage" className='coverimage' />
    
    <img src={mobile} alt="image" width="100%" className='innercoverphoto'/>
  

    </div> */}
    </div>
  
    <div className='d-flex'>
    <div className='outercoverdiv col-lg-4 '>
    <img src={phonecover} alt="coverimage" className='coverimage' />
    
    <img src={mobile} alt="image" width="100%" className='innercoverphoto'/>
  

    </div>
    <div className='outercoverdiv col-lg-4'>
    <img src={phonecover} alt="coverimage" className='coverimage' />
    
    <img src={mobile} alt="image" width="100%" className='innercoverphoto'/>
  

    </div>
    <div className='outercoverdiv col-lg-4'>
    <img src={phonecover} alt="coverimage" className='coverimage' />
    
    <img src={mobile} alt="image" width="100%" className='innercoverphoto'/>
  

    </div>
     {/* <div className='outercoverdiv col-lg-4'>
    <img src={phonecover} alt="coverimage" className='coverimage' />
    
    <img src={mobile} alt="image" width="100%" className='innercoverphoto'/>
  

    </div>
    <div className='outercoverdiv col-lg-4'>
    <img src={phonecover} alt="coverimage" className='coverimage' />
    
    <img src={mobile} alt="image" width="100%" className='innercoverphoto'/>
  

    </div> */}
    </div>
    </Slider>

          <div className='portfolioimagecoldivtitle p-2'>
          <h4 className='text-start'>title</h4>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged</p>
            </div>
           </div>
      
  )}
  </div>
     
     </div>
     
    </div>
    </>
  )
}

export default MobilePortfolio

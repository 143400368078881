import React from "react";
import CountUp from 'react-countup';
import f from "../assets/Frame.png";
import f2 from '../assets/Frame2.png';
import f3 from '../assets/Frame3.png';
import f4 from '../assets/Frame4.png';
import f5 from '../assets/Frame5.png';
import f6 from '../assets/Frame6.png';
import I from "../assets/wall.png";
import S from "../assets/samad1.jpg";
import H from "../assets/samad2.jpg";
import A from "../assets/samad3.jpg";
import N from "../assets/samad4.jpg";
import k1 from "../assets/k1.jpg";
import k2 from "../assets/k2.jpg";
import k3 from "../assets/k3.jpg";
import findus from '../assets/findUs.svg' ;
import services from '../assets/services.svg';
import factory from '../assets/factory.svg';
import contactus from '../assets/contactus.svg'

import {Link}from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Typewriter } from "react-simple-typewriter";
import group from '../assets/group.svg';
import group2 from '../assets/Group2.svg';
import group3 from '../assets/group3.svg';
import group4 from '../assets/group4.svg'
const DigitalTransform = ({ myListshow }) => {
  var settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 175,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots:false,
   
  arrows:false
  };

  const handleType = (count) => {
    // access word count number
    //console.log(count)}
  };

  const handleDone = () => {
    //console.log(`Done after 5 loops!`)
  };
  return (
    <>
      <div
        className={
          myListshow
            ? "hideme"
            : "container digitalContainer"
        }
      >
        <div className="position-relative">
          <div className="position-absolute spiralimgdiv">
            <img src={I} alt="image" className="digitalImage" />
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-md-6 col-lg-5  col-sm-12 digitaldiv">
              <p> We do Digital Transformations with Bespoke Solutions</p>
              <div className=" text-dark fw-semibold">
                <p className="paragraph-text">
                  We Develop CRM/ ERP/ CMS/ TMS/ LMS/ eCommerce/ Plugins/
                  Extensions/ Reporting/ Insights/ Integrations.
                </p>
              </div>
              <div className="digitalbtndiv">
                <button className="digitalbtn">LET'S DISCUSS</button>
              </div>
            </div>
            <div className="col-md-5 col-sm-11 col-lg-5 digitalsliderdiv22">
              <div>
                <Slider {...settings}>
                  <div className="dgdiv">
                    <img src={S} alt="image" className="imagedgslider" />
                  </div>
                  <div className="dgdiv">
                    <img src={k1} alt="image" className="imagedgslider" />
                  </div>
                  <div className="dgdiv">
                    <img src={k2} alt="image" className="imagedgslider" />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 ">
          <div className="row wecreaterow">
            <div className="wecreatediv">
              <h1>We Create</h1>
              <h1 style={{ color: "skyblue", fontWeight: "bold" ,fontSize:'50px' }}>
                <Typewriter
                  words={["WEBSITES", "MOBILE APPS", "DIGITAL MARKETING"]}
                  loop={false}
                  cursor
                  cursorStyle="_"
                  typeSpeed={70}
                  deleteSpeed={50}
                  delaySpeed={1000}
                  onLoopDone={handleDone}
                  onType={handleType}
                />
              </h1>
              <h1>According to Your Needs</h1>
            </div>
          </div>
        </div>

        <div className=" position-relative">
          <div className="row doforyoudiv">
            <div className=" col-sm-12 col-md-5 col-lg-5 doforyouimgdiv">
              <img src={A} alt="image" className="doforyouimg" />
            </div>
            <div className="col-md-6 col-lg-6 doforyoutext">
              <h2>Let's Not Discuss The Methodologies</h2>
              <p>But,Let's Discuss What We Can Do For You!</p>
            </div>
            <div className="position-absolute doforyousideimgdiv">
              <img src={I} alt="image " />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2"></div>

      <div className="d-flex">
        <div className="position-relative ">
          <div className="position-absolute imageshowus">
            <img src={I} alt="image" width="205" />
          </div>
        </div>
        
        <div className="container ">
          <div className="row showusrow">
            <div className="col-md-6 col-lg-5 showussection ">
              <h2 className="solutions-heading">
                We Create Solutions That helps You to Manage Your
              </h2>

              <div className="d-flex flex-wrap photowrapper">
                <div className="d-flex">
                  <div>
                    <img src={f} alt="image" className="solutionsimg" />
                  </div>
                  <div>
                    <span className="fw-bold textphotowrapper">Money</span>
                  </div>
                </div>
                <div className="d-flex photodiv ">
                  <div>
                    <img src={f2} alt="image" className="solutionsimg" />
                  </div>
                  <div>
                    <span className="fw-bold textphotowrapper">Employes</span>
                  </div>
                </div>
                <div className="d-flex photodiv ">
                  <div>
                    <img src={f3} alt="image" className="solutionsimg" />
                  </div>
                  <div>
                    <span className="fw-bold textphotowrapper">Marketing</span>
                  </div>
                </div>
                <div className="d-flex">
                  <div>
                    <img src={f4} alt="image" className="solutionsimg" />
                  </div>
                  <div>
                    <span className="fw-bold textphotowrapper">Mochines</span>
                  </div>
                </div>
                <div className="d-flex photodiv ">
                  <div>
                    <img src={f5} alt="image" className="solutionsimg" />
                  </div>
                  <div>
                    <span className="fw-bold textphotowrapper">Processes</span>
                  </div>
                </div>
                <div className="d-flex photodiv ">
                  <div>
                    <img src={f6} alt="image" className="solutionsimg" />
                  </div>
                  <div>
                    <span className="fw-bold textphotowrapper">Sales</span>
                  </div>
                </div>
              </div>
              <div>
                <h2 className="showusheadnglast mt-3">
                  In Short, We help you automate every aspect of your business.
                </h2>
              </div>
            </div>
            
            <div className=" col-sm-12 col-md-5 col-lg-5 doforyouimgdiv2">
              <img src={N} alt="image" className="doforyouimg2" />
            </div>
            
          </div>
</div>

</div>

          {/* we understand */}
          <div className="container">
          <div className="row weunderstandrow">
          <div className="col-sm-12 col-md-5 mt-5">
          <div className=" doforyouimgdiv3">
              <img src={N} alt="image" className="doforyouimg3" />
            </div>
          </div>



          <div className="col-sm-12 col-md-6 col-md-mt-5 col-sm-mt-3 weunderstandtext">
            <div className=" mt-5 understandheader">
              <h2 className="section5header">We</h2>
              <h2 className="section5header ms-2">Understand</h2>
            </div>



            <div className="col-md-mt-5">
              <p className="section5para">your requirements,
              assets, flaws,processes and align everything on a cloud platform</p>
            </div>
          </div>


          {/* project counts */}
          
          </div>
          </div>
         

<div className="projectcountsdiv  mb-5">
<div className="d-flex  my-5 p-3">
  <div>
    <img src={group} alt="logo" />
  </div>
  <div className="d-flex flex-column ms-3">
    <h3 className="projectscounting"><CountUp end={1200} duration={1.75}/>+</h3>
    <p className="projects-para ">Projects Completed</p>
  </div>
</div>

<div className="d-flex  my-5 p-3">
  <div>
    <img src={group2} alt="logo" />
  </div>
  <div className="d-flex flex-column ms-3">
    <h3 className="projectscounting">3+</h3>
    <p className="projects-para ">Cloud Expertise</p>
  </div>
</div>
<div className="d-flex  my-5 p-3">
  <div>
    <img src={group3} alt="logo" />
  </div>
  <div className="d-flex flex-column ms-3">
    <h3 className="projectscounting">70+</h3>
    <p className="projects-para ">App Published</p>
  </div>
</div>
<div className="d-flex my-5 p-3">
  <div>
    <img src={group4} alt="logo" />
  </div>
  <div className="d-flex flex-column ms-3">
    <h3 className="projectscounting">6+</h3>
    <p className="projects-para ">Frameworks</p>
  </div>
</div>
          </div>
          
        



{/* contact form */}




{/* footer div */}


    </>
  );
};



export default DigitalTransform;

import React,{useState,useEffect} from 'react';
import portfolioBack from '../assets/portfoliobackground.webp'
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import '../style/portfolio.css';
import image11 from '../assets/navy.jpg'
import dataFile from '../Data/dataFile';
const WebPortfolio = () => {
const[page,setPage]=useState(1);
const[pageCount,setPagecount]=useState(0);
const[pageSlicedarray,setpageSlicedarray]=useState([])
const[mainarray,setmainarray]=useState(dataFile)
useEffect(()=>{
  console.log('Data => ', dataFile);
  // calculate page count
  const pagedatacount=Math.ceil(dataFile.length/6);
  console.log("pagedatacount is=",pagedatacount)
  setPagecount(pagedatacount);
  const limit=6;
  const end=limit*page;
  const start=end-limit;
  const slicedArray=mainarray.slice(start,end);
  console.log("sliced array is====",slicedArray)
  setpageSlicedarray(slicedArray)

},[page])


    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1, // Number of slides to show at a time
      slidesToScroll:1,
      arrows:false,
       // Number of slides per row
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow:1,
            arrows:false
             // Adjust the number of slides per row for different breakpoints
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow:1,
            slidesToScroll:1,
          },
        },


        {
          breakpoint: 320,
          settings: {
            slidesToShow:1,
            slidesToScroll:1,
          },
        },
      ],


       
      }
      
      const handlePrevious=()=>{
        console.log("handle previous called")
        if(page==1)return page;
       setPage(page-1);

      }


      const handleNext=()=>{
        console.log("handle next called")
        if(page==pageCount)
        {
          return page;
        }
        setPage(page+1)
      }
  return (
    <>
    <div className='portfoliobackImagediv'>
    <div className='container'>
        <div className='row'>
        <div className="col-md-12">
         <h2 className="portfolioheader portfolioheadertext">Portfolio</h2>
        
            <h5 className='mt-3'>
                <Link to="/" className='text-decoration-none'>
                <span className="portfoliohometext portfolioheader  portfoliotextsection ml-2">HOME</span>
                </Link>                
                <span className="ms-1 portfolioheader  portfoliotextsection">PORTFOLIO</span>
                </h5>
            
        </div>    
        </div>
    </div>
    <div className='downdiv'>
        <a href="#focussection">
        <i className="fa-solid fa-arrow-down arrowcolor"></i>
        </a>
    </div>
    </div>
<div className="portfoliosliderdiv py-5 ">
<div className="container">
<div className='row'>
    <div className='d-flex industrydiv '>
      <div className=' me-4 industrydiv text-start'><label className='fw-bold arrowcolor'>By Industry:</label></div>  
      <div className='industryremainingdiv '>
      

      <Slider {...settings}>
      <div className="   d-flex justify-content-start">
        <button className=" col-lg-2 col-md-2 col-sm-2 col-xs-1 sliderbtn">ALL</button>
        <button className=" col-lg-2  col-md-2 col-sm-2 col-xs-1 sliderbtn">Tourism & Hospitality</button>
        <button className=" col-lg-2 col-md-2 col-sm-2 col-xs-1  sliderbtn">Retail & Ecommerce</button>
        <button className=" col-lg-2 col-md-2 col-sm-2 col-xs-1 sliderbtn">Real Estate</button>
        <button className=" col-lg-2 col-md-2 col-sm-2 col-xs-1 sliderbtn">Media & Entertainment</button>
        

      
      </div>


      <div className=' d-flex'>
        <button className="col-lg-2 col-md-2 col-sm-2 col-xs-1 sliderbtn">IT</button>
        <button className="col-lg-2 col-md-2 col-sm-2 col-xs-1 sliderbtn">Health & Fitness</button>
        <button className="col-lg-2 col-md-2 col-sm-2 col-xs-1 sliderbtn">Food & Beverages</button>
        <button className="col-lg-2 col-md-2 col-sm-2 col-xs-1 sliderbtn">Engineering & Construction</button>
        <button className="col-lg-2 col-md-2 col-sm-2 col-xs-1 sliderbtn">Education</button>
      </div>
      
    </Slider>


      </div>
    </div>
</div>
<div className='row mt-4'>
    <div className='d-flex industrydiv '>
      <div className=' me-4 industrydiv text-start'><label className='fw-bold arrowcolor'>By Technology:</label></div>  
      <div className='industryremainingdiv '>
      

      <Slider {...settings}>
      <div className="   d-flex justify-content-start">
        <button className=" col-lg-2 col-md-2 col-sm-2 col-xs-1 sliderbtn">ALL</button>
        <button className=" col-lg-2  col-md-2 col-sm-2 col-xs-1 sliderbtn">x-Cart</button>
        <button className=" col-lg-2 col-md-2 col-sm-2 col-xs-1  sliderbtn">Wordpress</button>
        <button className=" col-lg-2 col-md-2 col-sm-2 col-xs-1 sliderbtn">Woocommerce</button>
        <button className=" col-lg-2 col-md-2 col-sm-2 col-xs-1 sliderbtn">Squarespace</button>
        

      
      </div>


      <div className='  d-flex'>
        <button className="col-lg-2 col-md-2 col-sm-2 col-xs-1 sliderbtn">Shopify</button>
        <button className="col-lg-2 col-md-2 col-sm-2 col-xs-1 sliderbtn">React</button>
        <button className="col-lg-2 col-md-2 col-sm-2 col-xs-1 sliderbtn">PHP</button>
        <button className="col-lg-2 col-md-2 col-sm-2 col-xs-1 sliderbtn">Node js</button>
        <button className="col-lg-2 col-md-2 col-sm-2 col-xs-1 sliderbtn">Laravel</button>
      </div>
      
    </Slider>


      </div>
    </div>
</div>
    </div>
    </div>
    <div className='portfolioprojectdiv ' id="focussection">
     <div className='container'>
     <div className='row'>
      {
        pageSlicedarray.map((elem,index)=> 
        <div className='col-lg-6 col-md-11 mb-4' key={index}>
          <div className='p-3 portfolioimagecoldiv'>
          {/* {elem.image} */}
           {/* <img src={'../assets/aitutor.jpg'} alt="image" width="99%" className='portfolioimagecol'/> */}
           <img src={elem?.image} alt="image" width="99%" className='portfolioimagecol'/>
           </div>   
          <div className='portfolioimagecoldivtitle'>
          <h2 className='text-start'>{elem.title}</h2>
            </div>
           </div>
        )
      }
      </div>
     
     </div>
     
    </div>


    <div className='d-flex justify-content-center py-5'>
    <nav aria-label="Page navigation example">
  <ul className="pagination">
    <li className="page-item">
      <a className="page-link" href="#" aria-label="Previous" onClick={handlePrevious}>
        <span aria-hidden="true">&laquo;</span>
      </a>
    </li>
    {
      Array(pageCount).fill(null).map((elem,index)=>{


        return<li className={page==index+1? 'page-item active ':'page-item '}
        
        onClick={()=>setPage(index+1)}>
          <a class="page-link" href="#">{index+1}</a></li>

      })
    }
   
    <li class="page-item" onClick={handleNext}>
      <a class="page-link" href="#" aria-label="Next" >
        <span aria-hidden="true">&raquo;</span>
      </a>
    </li>
  </ul>
</nav>
    </div>
    </>
  )
}

export default WebPortfolio
